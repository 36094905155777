/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Header from "./header"
import "./layout.css"

const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  height: 80vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Layout = ({ logo, children }) => {
  return (
    <>
      <Header logo={logo} />
      <ContentContainer
      >
        <main>{children}</main>
      </ContentContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
