import React from "react"

import { Navbar } from "react-bootstrap"

const Header = ({ logo }) => (
  <Navbar>
    <Navbar.Brand>
      <img src={logo} alt={"codeToLove logo"}/>
    </Navbar.Brand>
  </Navbar>
)

export default Header
